import * as React from 'react';
import { Button, notification, Popconfirm, Modal } from 'antd';
import {
  SalesNodeEdgeMutation,
  SalesNodeEdgeQuery,
  salesObject,
  SalesError,
  salesErrorObject,
  ProductDetailNodeEdge,
  SalesProductDetails,
  SalesDiscountDetails,
} from './constants';
import { SalesService } from '../../service/SalesService';
import { CustomerService } from '../../service/CustomerService';
import { SalesForm } from './SalesForm';
import { CompanyNodeEdge, Bussiness } from '../master/Company/constants';
import { forEach } from 'lodash';
import { ProductNode, CustomerNode, NewStockReport } from '../../schema';
import { AddCustomer } from '../master/Customer/AddCustomer';
import { Customer } from '../master/Customer/Customer';
import moment from 'moment';
import { getDiscounts } from '../../service/DiscountService';
import { DiscountNodeEdge } from '../master/Discount/constants';

export class AddSales extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sales: this.handleSalesPropsUpdate(props),
      error: this.handleErrorPropsUpdate(props),
      buttonDisabled: false,
      customerObj: null,
      productObjList: [],
      oldProductObjList: [],
      modalAction: 'CLOSE_MODAL',
      updateCount: 0,
      totalQuantity: 0,
      service: false,
      discountDetails: [],
    };
  }

  salesService = new SalesService();
  customerService = new CustomerService();

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      sales: this.handleSalesPropsUpdate(nextProps),
      error: this.handleErrorPropsUpdate(nextProps),
    });
  }

  handleSalesPropsUpdate(nextProps: Props) {
    const sales: any = nextProps.sales
      ? {
          ...nextProps.sales,
          customerId: nextProps.sales.customer && nextProps.sales.customer.id,
          salesPersonId:
            nextProps.sales.salesPerson && nextProps.sales.salesPerson.id,
          branchId: nextProps.sales.branch?.id,
          productDetails: nextProps.sales.productDetails?.edges.map((pd) => ({
            ...pd.node,
            productId: pd.node.product?.id,
            gstRate: Number(pd.node.gstRate),
            movementType: this.state?.service ? 'SERVICE' : 'SALE',
          })),
          oldProductDetails: nextProps.sales.oldProductDetails?.edges.map(
            (pd) => ({ ...pd.node, productId: pd.node.product?.id }),
          ),
          paymentModes: nextProps.sales.paymentModes.edges.map((mpm) => ({
            ...mpm.node,
          })),
        }
      : JSON.parse(JSON.stringify(salesObject));
    if (nextProps.sales) {
      delete sales.customer;
      delete sales.salesPerson;
      delete sales.branch;
    }
    if (!nextProps.sales && this.state?.service) {
      sales.productDetails[0].movementType = 'SERVICE';
    }
    return sales;
  }

  handleErrorPropsUpdate(nextProps: Props) {
    const error: SalesError = JSON.parse(JSON.stringify(salesErrorObject));
    nextProps.sales &&
      nextProps.sales.paymentModes.edges.forEach(() => {
        if (
          nextProps.sales?.paymentModes.edges.length !==
          error.paymentModes.length
        ) {
          error.paymentModes.push({
            paymentType: '',
            amount: '',
          });
        }
      });
    nextProps.sales &&
      nextProps.sales.productDetails?.edges.forEach(() => {
        if (
          nextProps.sales?.productDetails?.edges.length !==
          error.productDetails.length
        ) {
          error.productDetails.push({
            productId: '',
            rate: '',
            quantity: '',
            discount: '',
            gstRate: '',
            amount: '',
          });
        }
      });
    nextProps.sales?.oldProductDetails?.edges.forEach(() => {
      if (
        nextProps.sales?.oldProductDetails?.edges.length !==
        error.oldProductDetails.length
      ) {
        error.oldProductDetails.push({
          productId: '',
          rate: '',
          quantity: '',
          amount: '',
        });
      }
    });
    return error;
  }

  fetchDiscount = async () => {
    const discounts = await getDiscounts({
      date: this.state.sales.date,
      branchId: this.props.business.id,
      companyId: this.props.company.id,
    });
    const discountDetails: SalesDiscountDetails[] = [];
    discounts.forEach((value: DiscountNodeEdge) => {
      discountDetails.push({
        category: value.node.category?.name ?? '',
        discountPercent: value.node.discountPercent ?? 0,
      });
    });
    this.setState({ discountDetails });
    this.handleDiscountOnDateChange();
  };

  componentDidMount() {
    if (
      this.props.company.name.toLowerCase().includes('khadi') ||
      this.props.company.name.toLowerCase().includes('sugar')
    ) {
      this.fetchDiscount();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ): void {
    if (
      (this.props.company.name.toLowerCase().includes('khadi') ||
        this.props.company.name.toLowerCase().includes('sugar')) &&
      this.state.sales.date !== prevState.sales.date
    ) {
      this.fetchDiscount();
    }
  }

  onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number | boolean | null } },
  ) => {
    const sales = { ...this.state.sales };
    sales[e.target.name] = e.target.value;
    if (e.target.name === 'toRedeem') {
      sales.redeemValue = sales.toRedeem
        ? Number(
            (
              Number(sales.redeemablePoint) *
              (this.props.company.amountPerPointRedeem || 0)
            ).toFixed(2),
          )
        : 0;
    }
    if (
      ['discount', 'toRedeem', 'valueAdditionAmount'].indexOf(e.target.name) >
      -1
    ) {
      this.handleAmounts(sales);
    }
    if (e.target.name === 'isExchange' && !e.target.value) {
      sales.oldProductDetails = JSON.parse(
        JSON.stringify(salesObject.oldProductDetails),
      );
      this.handleAmounts(sales);
    }
    if (e.target.name === 'isValueAddition' && !e.target.value) {
      sales.valueAdditionRemarks = JSON.parse(
        JSON.stringify(salesObject.valueAdditionRemarks),
      );
      sales.valueAdditionAmount = JSON.parse(
        JSON.stringify(salesObject.valueAdditionAmount),
      );
      this.handleAmounts(sales);
    }
    if (e.target.name === 'customerPaid') {
      const cashPaid = sales.paymentModes.find(
        (pm) => pm.paymentType === 'Cash',
      );
      sales.balanceRefund = cashPaid
        ? (sales.customerPaid || 0) - cashPaid.amount
        : 0;
    }
    if (e.target.name === 'service') {
      this.setState({ service: !!e.target.value });
      const sales = JSON.parse(JSON.stringify(salesObject));
      if (!!e.target.value) {
        sales.productDetails[0].movementType = 'SERVICE';
      }
      this.setState({ sales });
      return;
    }
    this.setState({ sales });
  };

  checkError = () => {
    const error = { ...this.state.error };
    let isError = false;
    const requiredFields = [
      'date',
      'billAmount',
      'toRedeem',
      'grossAmount',
      'netAmount',
      'totalAmount',
      'isValueAddition',
      'isExchange',
    ];
    if (this.props.company.name.toLowerCase().includes('jharcraft')) {
      requiredFields.push('salesPersonId');
    }
    forEach(this.state.sales, (value, key) => {
      if (
        typeof value === 'string' &&
        requiredFields.indexOf(key) > -1 &&
        !value.trim() &&
        key !== 'netAmount'
      ) {
        isError = true;
        error[key] = `This is a Required field`;
      } else if (
        !value &&
        value !== false &&
        requiredFields.indexOf(key) > -1 &&
        key !== 'netAmount'
      ) {
        isError = true;
        error[key] = `This is a Required field`;
      } else if (requiredFields.indexOf(key) > -1 && key !== 'netAmount') {
        error[key] = '';
      }
      if (key === 'netAmount' && !value && value !== 0) {
        isError = true;
        error[key] = `This is a Required field`;
      } else if (key === 'netAmount') error[key] = '';
    });
    const sales = this.state.sales;
    error.paymentModes.map((epm, index) => {
      Object.keys(epm).forEach((pmkey) => {
        if (
          sales.paymentModes[index][pmkey] ||
          (sales.paymentModes[index][pmkey] === 0 && sales.netAmount === 0)
        ) {
          epm[pmkey] = '';
        } else {
          isError = true;
          epm[pmkey] = `This is a required field`;
        }
      });
      return epm;
    });
    if (sales.balanceRefund && sales.balanceRefund < 0) {
      isError = true;
      notification.error({
        message: 'Tender Amount Should not be less than Amount',
      });
    }
    if (!sales.oldBillNo && sales.isExchange) {
      isError = true;
      error.oldBillNo = `This is a Required field`;
    }
    this.setState({ error });
    return isError;
  };

  customError = () => {
    const error = { ...this.state.error };
    let isError = false;
    if (
      this.state.sales.paymentModes.reduce(
        (sum, pm) => sum + Number(pm.amount),
        0,
      ) !== this.state.sales.netAmount
    ) {
      isError = true;
      error.paymentModes[
        error.paymentModes.length - 1
      ].amount = `Total Payment Amount is not equal to Net Amount`;
    } else {
      error.paymentModes[error.paymentModes.length - 1].amount = ``;
    }
    if (
      this.state.sales.isValueAddition &&
      (!this.state.sales.valueAdditionRemarks ||
        !this.state.sales.valueAdditionAmount)
    ) {
      isError = true;
      this.state.sales.valueAdditionRemarks ||
        (error.valueAdditionRemarks = `This is a required field`);
      this.state.sales.valueAdditionAmount ||
        (error.valueAdditionAmount = `This is a required field`);
    } else {
      error.valueAdditionRemarks = '';
      error.valueAdditionAmount = '';
    }
    this.setState({ error });
    return isError;
  };

  checkProductError = () => {
    const sales = { ...this.state.sales };
    const productDetails: SalesProductDetails[] = {
      ...(this.state.sales.productDetails || []),
    };
    const oldProductDetails: ProductDetailNodeEdge[] = {
      ...(this.state.sales.oldProductDetails || []),
    };
    let isError = false;
    const error = { ...this.state.error };
    error.productDetails.map((epd, index) => {
      Object.keys(epd).forEach((pdkey) => {
        if (
          !productDetails[index][pdkey] &&
          ['discount', 'gstRate'].indexOf(pdkey) === -1
        ) {
          isError = true;
          epd[pdkey] = `This is a required field`;
        } else {
          epd[pdkey] = '';
        }
        if (pdkey === 'discount' && (productDetails[index][pdkey] || 0) > 100) {
          isError = true;
          epd[pdkey] = `Discount can\'t be more than 100%`;
        }
        if (
          this.props.company.name.toLowerCase().includes('jharcraft') &&
          pdkey === 'discount' &&
          (productDetails[index][pdkey] || 0) > 15
        ) {
          isError = true;
          epd[pdkey] = `Maximum discount allowed is 15%.`;
        }
        if (
          pdkey === 'gstRate' &&
          !productDetails[index][pdkey] &&
          productDetails[index][pdkey] !== 0
        ) {
          isError = true;
          epd[pdkey] = `This is a required field`;
        }
        if (
          this.props.company.name.toLowerCase().includes('pearl') &&
          pdkey === 'quantity' &&
          (productDetails[index][pdkey] || 0) >
            (productDetails[index].totalStock || 0)
        ) {
          isError = true;
          epd[pdkey] = 'Product stock is less than sale quantity';
        }
        // if (
        //   pdkey === "quantity" &&
        //   !productDetails[index].allowDecimal &&
        //   productDetails[index][pdkey] % 1 !== 0
        // ) {
        //   isError = true;
        //   epd[pdkey] = 'Decimal value is not allowed for this product';
        // }
      });
      return epd;
    });
    if (sales.isExchange) {
      error.oldProductDetails.map((epd, index) => {
        Object.keys(epd).forEach((pdkey) => {
          if (!oldProductDetails[index][pdkey]) {
            isError = true;
            epd[pdkey] = `This is a required field`;
          } else {
            epd[pdkey] = '';
          }
        });
        return epd;
      });
    }
    this.setState({ error });
    return isError;
  };

  handleProductDiscount(nextState: State, index: number) {
    const productObj = this.state.productObjList[index];
    if (nextState.sales.productDetails && productObj) {
      const category = this.props.company.name.toLowerCase().includes('khadi')
        ? productObj.productType?.toLowerCase()
        : productObj.discountCategory?.name?.toLowerCase();
      if (category) {
        const discountDetails = [...this.state.discountDetails];
        const idx = discountDetails.findIndex(
          (el) => el.category.toLowerCase() == category,
        );
        if (idx !== -1 && nextState.sales.productDetails[index].quantity != 0) {
          nextState.sales.productDetails[index].discount =
            discountDetails[idx].discountPercent;
        } else {
          nextState.sales.productDetails[index].discount = 0;
        }
      }
    }
  }

  handleDiscountOnDateChange() {
    const nextState = { ...this.state };
    nextState.sales.productDetails?.forEach((el, idx) =>
      this.handleProductDiscount(nextState, idx),
    );
    this.setState(nextState);
  }

  handleAmounts(sales: SalesNodeEdgeMutation) {
    sales.totalAmount = Number(
      sales.productDetails
        ?.reduce((sum, p) => sum + (p.quantity || 0) * (p.rate || 0), 0)
        .toFixed(2),
    );
    const billAmount = sales.productDetails?.reduce(
      (sum, p) => sum + (p.amount || 0),
      0,
    );
    sales.billAmount = Number(billAmount?.toFixed(2));
    const grossAmount = (sales.grossAmount =
      (billAmount || 0) - ((billAmount || 0) * sales.discount) / 100);
    let cgst = Number(
      (
        (grossAmount -
          grossAmount / (1 + 0.01 * (this.props.company.gstRate || 0))) /
        2
      ).toFixed(2),
    );
    if (!cgst) {
      cgst =
        ((billAmount || 0) -
          (sales.productDetails?.reduce(
            (sum, pd) => sum + (pd.amount || 0) / (1 + (pd.gstRate || 0) / 100),
            0,
          ) || 0)) /
        2;
    }
    sales.cgst = sales.sgst = cgst;
    let oldBillAmount = 0;
    if (sales.isExchange) {
      oldBillAmount =
        sales.oldProductDetails?.reduce(
          (sum, p) => sum + Number(p.amount),
          0,
        ) || 0;
    }
    sales.netAmount = Math.round(
      grossAmount +
        Number(sales.valueAdditionAmount) -
        sales.redeemValue -
        oldBillAmount,
    );
    sales.paymentModes[0].amount = sales.netAmount;
  }

  productChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    if (nextState.sales.productDetails) {
      nextState.sales.productDetails[index][e.target.name] = e.target.value;
      if (
        e.target.name === 'quantity' &&
        (this.props.company.name.toLowerCase().includes('khadi') ||
          this.props.company.name.toLowerCase().includes('sugar'))
      ) {
        this.handleProductDiscount(nextState, index);
      }

      if (nextState.sales.productDetails[index].productId) {
        const product = this.state.productObjList[index];
        const amount =
          Number(nextState.sales.productDetails[index].quantity) *
          Number(product.sellingRate);
        nextState.sales.productDetails[index].amount =
          amount -
          (amount * Number(nextState.sales.productDetails[index].discount)) /
            100;
        const rateAfterDiscount =
          (nextState.sales.productDetails[index].rate || 0) -
          ((nextState.sales.productDetails[index].rate || 0) *
            (nextState.sales.productDetails[index].discount || 0)) /
            100;
        nextState.sales.productDetails[index].gstRate = product.hsn
          ? product.hsn.hsnWithSameCode.length > 1
            ? product.hsn.hsnWithSameCode.find(
                (hsn) =>
                  hsn.minValue !== undefined &&
                  hsn.minValue <= rateAfterDiscount &&
                  hsn.maxValue !== undefined &&
                  hsn.maxValue >= rateAfterDiscount,
              )?.gst
            : product.hsn.hsnWithSameCode[0].gst
          : 0;
      }
      this.calculateTotalQuantity(nextState);
      this.handleAmounts(nextState.sales);
      this.setState(nextState);
    }
  };
  calculateTotalQuantity = (nextState: State) => {
    nextState.totalQuantity = Number(
      nextState.sales.productDetails
        ?.reduce((total, product) => total + Number(product.quantity), 0)
        .toFixed(2),
    );

    this.setState(nextState);
  };

  paymentModeChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    nextState.sales.paymentModes[index][e.target.name] = e.target.value;
    if (
      e.target.name === 'amount' &&
      nextState.sales.paymentModes.length === 2
    ) {
      nextState.sales.paymentModes[1 - index].amount =
        (nextState.sales.netAmount || 0) - Number(e.target.value);
    }
    if (nextState.sales.customerPaid) {
      const cashPaid = nextState.sales.paymentModes.find(
        (pm) => pm.paymentType === 'Cash',
      );
      nextState.sales.balanceRefund = cashPaid
        ? nextState.sales.customerPaid - cashPaid.amount
        : 0;
    }
    this.setState(nextState);
  };

  oldProductChangeHandler = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string | number } },
    index: number,
  ) => {
    const nextState: State = {
      ...this.state,
    };
    if (nextState.sales.oldProductDetails) {
      nextState.sales.oldProductDetails[index][e.target.name] = e.target.value;
      if (
        nextState.sales.oldProductDetails[index].quantity &&
        nextState.sales.oldProductDetails[index].amount
      ) {
        nextState.sales.oldProductDetails[index].rate =
          Number(
            (
              Number(nextState.sales.oldProductDetails[index].amount) /
              Number(nextState.sales.oldProductDetails[index].quantity)
            ).toFixed(2),
          ) || 0;
      }
      this.handleAmounts(nextState.sales);
      this.setState(nextState);
    }
  };

  updateProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.sales.productDetails?.push({
        date: null,
        productId: '',
        quantity: null,
        rate: null,
        discount: 0,
        amount: null,
        movementType: this.state.service ? 'SERVICE' : 'SALE',
        companyId: null,
      });
      nextState.error.productDetails.push({
        productId: '',
        rate: '',
        quantity: '',
        discount: '',
        gstRate: '',
        amount: '',
      });
    } else {
      nextState.sales.productDetails?.splice(index, 1);
      nextState.error.productDetails.splice(index, 1);
      this.handleAmounts(nextState.sales);
    }
    this.setState(nextState);
  };

  updateOldProductsRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.sales.oldProductDetails?.push({
        date: null,
        productId: '',
        quantity: 0,
        rate: 0,
        amount: 0,
        movementType: 'EXCHANGE',
        companyId: null,
      });
      nextState.error.oldProductDetails.push({
        productId: '',
        rate: '',
        quantity: '',
        amount: '',
      });
    } else {
      nextState.sales.oldProductDetails?.splice(index, 1);
      nextState.error.oldProductDetails.splice(index, 1);
      this.handleAmounts(nextState.sales);
    }
    this.setState(nextState);
  };

  productObjChangehandler = (productObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    if (nextState.productObjList && nextState.sales.productDetails) {
      nextState.productObjList[index] = productObj;
      nextState.sales.productDetails[index].rate = productObj.sellingRate;
      nextState.sales.productDetails[index].purchaseRate =
        productObj.purchaseRate;
      nextState.sales.productDetails[index].gstRate =
        productObj.hsn && productObj.hsn.gst;
      nextState.sales.productDetails[index].totalStock = (
        productObj.newStock as unknown as NewStockReport
      ).totalStocks;
      nextState.sales.productDetails[index].allowDecimal =
        productObj.unit.allowDecimal;
      if (this.props.company.gstRate) {
        nextState.sales.productDetails[index].gstRate =
          this.props.company.gstRate;
      }
      this.setState(nextState);
    }
  };

  oldProductObjChangehandler = (oldProductObj: ProductNode, index: number) => {
    const nextState = { ...this.state };
    nextState.oldProductObjList[index] = oldProductObj;
    if (nextState.sales.oldProductDetails) {
      nextState.sales.oldProductDetails[index].purchaseRate =
        oldProductObj.purchaseRate;
      nextState.sales.oldProductDetails[index].gstRate =
        oldProductObj.hsn && oldProductObj.hsn.gst;
    }
    this.setState(nextState);
  };

  customerObjChangehandler = (customerObj: CustomerNode) => {
    const nextState = { ...this.state };
    nextState.customerObj = customerObj;
    nextState.sales.customerId = customerObj.id;
    if (customerObj.gstinNumber || customerObj.gstinLegalName) {
      nextState.sales.gstinNumber = customerObj.gstinNumber;
      nextState.sales.gstinLegalName = customerObj.gstinLegalName;
    }
    nextState.sales.redeemablePoint = Number(
      Number(customerObj.pointBalance).toFixed(2),
    );
    this.setState(nextState);
  };

  updatePaymentModeRow = (type: string, index: number) => {
    const nextState: State = { ...this.state };
    if (type === '+') {
      nextState.sales.paymentModes.push({
        date: moment().format('YYYY-MM-DD'),
        paymentType: 'Cash',
        amount:
          (nextState.sales.netAmount || 0) -
          nextState.sales.paymentModes[0].amount,
      });
      nextState.error.paymentModes.push({
        paymentType: '',
        amount: '',
      });
    } else {
      nextState.sales.paymentModes.splice(index, 1);
      nextState.sales.paymentModes[0].amount = nextState.sales.netAmount || 0;
      nextState.error.paymentModes.splice(index, 1);
    }
    this.setState(nextState);
  };

  handleModalAction = (
    modalAction: 'ADD_CUSTOMER' | 'LIST_CUSTOMER' | 'CLOSE_MODAL',
  ) => {
    this.setState({ modalAction });
  };

  saveSales = () => {
    if (this.checkError()) {
      return;
    }
    if (this.customError()) {
      return;
    }
    if (this.checkProductError()) {
      return;
    }
    this.setState({ buttonDisabled: true });

    const currentCustomer: CustomerNode = JSON.parse(
      JSON.stringify(this.state.customerObj),
    );
    let pointBalance = 0;
    if (!this.props.isEditActive) {
      currentCustomer &&
        this.props.company.amountPerPointSale &&
        (pointBalance =
          currentCustomer.pointBalance -
          this.state.sales.redeemablePoint +
          (this.state.sales.netAmount || 0) /
            this.props.company.amountPerPointSale);
    }

    const sales = { ...this.state.sales };
    sales.productDetails?.forEach((pd) => delete pd.totalStock);
    sales.productDetails?.forEach((pd) => delete pd.allowDecimal);

    this.salesService.addSales(
      sales,
      () => {
        if (!this.props.sales) {
          this.clear();
        }
        this.setState({ buttonDisabled: false });
        currentCustomer &&
          this.customerService?.addCustomer({
            ...currentCustomer,
            pointBalance,
          });
        notification.success({
          message: 'Sale ' + sales.id ? 'Updated' : 'Added',
          description: `Sale for bill no ${sales.billNo} was successfuly ${
            sales.id ? 'Updated' : 'Added'
          }`,
        });
        this.props.handleTabChange('1');
      },
      (error) => {
        notification.error({
          message: 'Sale ' + sales.id ? 'Update Error' : 'Add Error',
          description: JSON.stringify(error),
        });
        this.setState({ buttonDisabled: false });
      },
    );
  };

  clear = () => {
    const sales = JSON.parse(JSON.stringify(salesObject));
    if (this.state.service) sales.productDetails[0].movementType = 'SERVICE';
    this.setState({ sales: { ...sales }, service: false });
  };

  render() {
    return (
      <div>
        <SalesForm
          values={this.state.sales}
          error={this.state.error}
          onChange={this.onChange}
          isEditActive={this.props.isEditActive}
          company={this.props.company}
          business={this.props.business}
          productChangeHandler={this.productChangeHandler}
          updateProductsRow={this.updateProductsRow}
          oldProductChangeHandler={this.oldProductChangeHandler}
          updateOldProductsRow={this.updateOldProductsRow}
          paymentModeChangeHandler={this.paymentModeChangeHandler}
          updatePaymentModeRow={this.updatePaymentModeRow}
          productObjChangehandler={this.productObjChangehandler}
          oldProductObjChangehandler={this.oldProductObjChangehandler}
          handleModalAction={this.handleModalAction}
          customerObjChangehandler={this.customerObjChangehandler}
          updateCount={this.state.updateCount}
          totalQuantity={this.state.totalQuantity}
          service={this.state.service}
        />
        <Modal
          title="Add Customer"
          visible={this.state.modalAction === 'ADD_CUSTOMER'}
          footer={<div />}
          onCancel={() => this.handleModalAction('CLOSE_MODAL')}
        >
          <AddCustomer
            onCustomerAdded={() =>
              this.setState({ updateCount: this.state.updateCount + 1 })
            }
            company={this.props.company}
          />
        </Modal>
        <Modal
          title="Edit Customer"
          visible={this.state.modalAction === 'LIST_CUSTOMER'}
          footer={<div />}
          onCancel={() => this.handleModalAction('CLOSE_MODAL')}
        >
          <Customer
            isReportsNotVisible
            updateCount={this.state.updateCount}
            showOnlyList
          />
        </Modal>
        <Popconfirm
          title="Are you sure to clear?"
          onConfirm={this.clear}
          okText="Yes"
          cancelText="No"
        >
          <Button type="dashed" children="Clear" style={{ width: '50%' }} />
        </Popconfirm>
        <Button
          type="primary"
          onClick={this.saveSales}
          children="Submit"
          style={{ width: '50%' }}
          disabled={
            this.state.buttonDisabled || this.props.business.type === 'company'
          }
        />
      </div>
    );
  }
}

interface Props {
  sales?: SalesNodeEdgeQuery;
  handleTabChange: (key) => void;
  isEditActive: boolean;
  company: CompanyNodeEdge;
  business: Bussiness;
}

interface State {
  sales: SalesNodeEdgeMutation;
  error: SalesError;
  buttonDisabled: boolean;
  customerObj: CustomerNode | null;
  productObjList: ProductNode[];
  oldProductObjList: ProductNode[];
  modalAction: 'ADD_CUSTOMER' | 'LIST_CUSTOMER' | 'CLOSE_MODAL';
  updateCount: number;
  totalQuantity: number;
  service: boolean;
  discountDetails: SalesDiscountDetails[];
}
